import React, {Component} from 'react'
import { connect } from 'react-redux'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'
import { Oval } from 'react-loader-spinner'
import {actions as authActions} from '../../../redux/modules/Auth'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 
import BackOfficeSettingsMenuComponent from 'components/BackOfficeSettingsMenuComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import  * as regularIcons  from '@fortawesome/free-regular-svg-icons'
import { ADMIN } from 'libs/roles'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import BackofficeEditMarkType from 'components/MarkTypes/BackofficeEditMarkType'
import BackofficeEditMarkTypeModal from 'components/MarkTypes/BackofficeEditMarkTypeModal'
import { scroller , Element } from 'react-scroll'


/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    backofficeValues: state.form.backofficeSettings,
    auth: state.auth,
    stateReport : state.report,
    
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

/**
 * --------------------------------------------------------------------------
 */
class BackofficeSettingsMarkTypes extends Component {

  /**
   * --------------------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      success: false,
      loading: false,
      submitting: false,
      error: false,
      errorMsg: null,    
      markTypes: [],
      showEditMarkTypeModal: false,
      selectedMarkTypeId: null,
      showMaximizedList: true
    }
    
    this.onSubmit     = this.onSubmit.bind(this)
    this.setMarkPrice = this.setMarkPrice.bind(this)
    this.onSaveMarkType = this.onSaveMarkType.bind(this)
    this.onDeleteMarkType = this.onDeleteMarkType.bind(this)
    this.onCancelEditMarkType = this.onCancelEditMarkType.bind(this)
    this.onCloseEditMarkTypeModal = this.onCloseEditMarkTypeModal.bind(this)
  }

  /**
   * --------------------------------------------------------------------------
   */
  async componentDidMount () {
    this.fetchData()    
  }

  /**
   * --------------------------------------------------------------------------
   */
  async fetchData() {
    this.setState({loading: true})

    const markTypes = await SostaOnlineApiService.fetchMarkTypes()
        
    if ( markTypes != null && markTypes.data != null  )
      this.setState({ markTypes: markTypes.data })

    this.fetchThingsToDo()

    this.setState({loading: false})
  }

  /**
   * --------------------------------------------------------------------------
   */
  fetchThingsToDo () {
    SostaOnlineApiService.fetchThingsToDo().then(data => {      
      this.props.reportActions.fetchThingsToDo(data.data)
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }   

  /**
   * --------------------------------------------------------------------------
   * @param {*} e 
   */
  async onSubmit (e) {
    e.preventDefault()
    this.setState({submitting: true, error: null, success: false})
        
            
    this.state.markTypes.map( marktype => {
      console.log("MARK",marktype.id,marktype.price)
    })
    
    const result = await SostaOnlineApiService.updateMarkTypes(this.state.markTypes)
      .catch( error => {       
        this.setState({submitting: false, error: true, success: false, errorMsg: error.data.error })
      })      

    if (result && result.result === 'ok') {
      await this.fetchData()
      this.setState({submitting: false, error: false, success: true})
    } 
    else {
      this.setState({submitting: false, error: true, success: false})
    }
    
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} markTypeId 
   * @param {*} price 
   */
  setMarkPrice(markTypeId,price) {

    var markTypes = []

    markTypes = this.state.markTypes.map( marktype => {

      if ( marktype.id == markTypeId ) {
        marktype.price = price
      }
      
      return marktype

    } )

    this.setState({markTypes: markTypes})

  }

  /**
   * --------------------------------------------------------------
   * @param {*} id 
   */
  editMarkType(id) {       
    this.setState({
      selectedMarkTypeId: id      
    })

    this.scrollToDetail();
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  windowMaximize() {
    this.setState({ showEditMarkTypeModal: true })
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async onSaveMarkType(markTypeId ) {
    this.setState({submitting: true, error: null, success: false})
    await this.fetchData()
    this.editMarkType(markTypeId)
    this.setState({submitting: false, error: false, success: true})
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async onDeleteMarkType() {    
    this.setState({submitting: true, error: null, success: false})
    await this.fetchData()
    this.editMarkType(null)
    this.setState({submitting: false, error: false, success: true})    
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  onCancelEditMarkType() {
    //this.setState({ selectedMarkTypeId: null})   
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onCloseEditMarkTypeModal() {
    this.setState({      
      showEditMarkTypeModal: false
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} element 
   */
  scrollToDetail() {
    scroller.scrollTo("detail", {
      duration: 1000,
      delay: 100,
      smooth: true
    })
  }

  /**
   * --------------------------------------------------------------------------
   * @returns 
   */
  render () {    

    const {
      success,
      loading,
      submitting,
      error,
      errorMsg,
      markTypes,
      showEditMarkTypeModal,
      selectedMarkTypeId,
      showMaximizedList
    } = this.state


    var markTypesSorted = markTypes.sort((a, b) => (a.name > b.name ) ? 1 : -1)


    if (loading) {
      return (
        <React.Fragment>
        <h3><strong>Caricamento in corso...</strong></h3>
        <div className="row" style={{padding: "2em", height: "40em"}}>
          <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />                  
        </div>
        </React.Fragment>
      )
    } 
    if ( error )    {
      return (<div className="text-primary">{errorMsg}</div>)
    }

    if ( this.props.auth.user.role != ADMIN ) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }

    return (
      <React.Fragment>
        <BackOfficeSettingsMenuComponent {...this.props} />
        <div className="row">
          <div className="pull-left" style={{ margin: "1em", marginBottom: "0.5em" }}>
            <button className="mainBtn" onClick={ e => { this.editMarkType(null) } } ><FontAwesomeIcon  size="1x" icon={solidIcons.faPlusCircle} />
            <span className="hidden-xs hidden-sm">&nbsp;&nbsp;Crea Tipo Permesso</span></button>
          </div>
        </div>
        <div className="row" style={{  padding: 0,  margin: 0 }}>

          { showMaximizedList ? 
          <div className={ showEditMarkTypeModal ? "box col-md-12" :  "box col-md-4" } style={{ padding: "0.3em", margin: 0 , marginBottom: "0.3em" }}>
                  
            <div className="row backofficeSettingsHeader" >              
              <div className="col-xs-2 "><label>Id</label></div>
              <div className="col-xs-9 "><label>Nome</label></div>
              <div className="col-xs-1 pull-right" style={{ verticalAlign: "middle"}}>
                <FontAwesomeIcon  size="lg" className="mainIconsRev" icon={solidIcons.faCaretLeft} onClick={ () => { this.setState({showMaximizedList: false}) } } />
              </div>              
            </div>
            
            {markTypesSorted.map(markType =>   
              <OverlayTrigger key={`mark-${markType.id}`} placement="top" overlay={<Tooltip id={'tooltip-marktype-'+markType.id}>Clicca per modificare i dati del Tipo Permesso</Tooltip>}>
              <div className={ selectedMarkTypeId == markType.id ? "row backofficeSettingsRowSelected" :  "row backofficeSettingsRow"} onClick={ evt => { this.editMarkType(markType.id)} } >    
                <div className="col-xs-12 row">                                   
                  <div className="col-xs-1 "><label>{markType.id}</label></div>
                  <div className="col-xs-9 "><label>{markType.name}</label></div> 
                  <div className="col-xs-1 "><div style={{width:'30px', height:'30px', backgroundColor:markType.color}}></div></div>               
                  <div className="col-xs-1 pull-right" style={{ verticalAlign: "middle", textAlign:'center'}}>{ selectedMarkTypeId == markType.id ? <FontAwesomeIcon  size="lg" icon={solidIcons.faCaretRight} /> : "" }</div>
                </div>
              </div>
            </OverlayTrigger>                        
            )}

            <div className="form-group row backofficeSettingsFooter" >
              <div className="col-md-12 " ></div>            
            </div>
          
            {submitting && <div className="text-primary">
              Salvataggio in corso
            </div>}

            {error && <div className="text-danger">
              Si sono verificati degli errori
            </div>}

            {success && <div className="text-success">
              
              </div>}
              
          </div>         
          : 
          <div className="col-md-1" style={{ padding: 0, margin: 0 , marginBottom: "0.3em" }}>
            <div className="mainBtn" onClick={ () => { this.setState({showMaximizedList: true}) } } >
              <FontAwesomeIcon  size="lg" icon={solidIcons.faCaretDown} />
            </div>
          </div>
          }

          { !showEditMarkTypeModal && 
          <div className={ showMaximizedList ? "col-md-8": "col-md-11"} style={{  padding: "0.3em" , margin: 0 }}>
            <Element name="detail"/>
            <div className="backofficeSettingsBox" >
              <div className="backofficeSettingsBoxHeader row text-center">
                <div className="col-xs-1 pull-left">
                { selectedMarkTypeId != null 
                    ? <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faEdit} />
                    : <FontAwesomeIcon className="IconsRevNoAction"  size="1x" icon={solidIcons.faPlusCircle} />
                  }                
                </div>          
                <div className="col-xs-10 pull-left" >
                  <strong>{ selectedMarkTypeId != null ? "MODIFICA" : "CREA NUOVO" } TIPO PERMESSO </strong>
                </div>
                <div className="col-xs-1 pull-right" onClick={ evt => this.windowMaximize() }>
                  <OverlayTrigger placement="left" overlay={<Tooltip id={"tooltip-category-to-window"}>Mostra in finestra</Tooltip>}>
                    <FontAwesomeIcon  size="1x" className="mainIconsRev" icon={solidIcons.faWindowRestore} />
                  </OverlayTrigger>
                </div>
              </div>
              <div className="row" >                
                <BackofficeEditMarkType                
                  markTypeId={selectedMarkTypeId}
                  onSaveMarkType={this.onSaveMarkType}      
                  onDeleteMarkType={this.onDeleteMarkType}            
                  onCancelEditMarkType={this.onCancelEditMarkType}
                  />                
              </div>
            </div>
          </div>
          }
        </div>

        

        <BackofficeEditMarkTypeModal
          show={showEditMarkTypeModal}
          markTypeId={selectedMarkTypeId}
          onSaveMarkType={this.onSaveMarkType}      
          onDeleteMarkType={this.onDeleteMarkType}            
          onCancelEditMarkType={this.onCancelEditMarkType}
          onClose={this.onCloseEditMarkTypeModal}
          />
      
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackofficeSettingsMarkTypes)
